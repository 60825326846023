'use client';

import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

import { cn } from '@/lib/tailwind';
import Icon from '@/external/icon';

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn('pl-6 py-12 border-b border-black', className)}
    {...props}
  />
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Trigger
    ref={ref}
    className={cn(
      'grid text-start grid-cols-[16px_1fr] items-center text-mobile-sm/body-1-bold gap-4',
      'tablet-sm:text-tablet-sm/heading-6 tablet-sm:gap-6 tablet-sm:grid-cols-[24px_1fr]',
      'desktop-lg:text-desktop-large/heading-6',
      '[&[data-state=open]>.accordionCollapsedIcon]:hidden [&[data-state=open]>.accordionExpandedIcon]:block',
      className,
    )}
    {...props}
  >
    <Icon
      icon="plus"
      className="h-4 w-4 p-1 tablet-sm:h-6 tablet-sm:w-6 tablet-sm:p-1.5 bg-mintyBlueGradient rounded-full accordionCollapsedIcon"
    />
    <Icon
      icon="minus"
      className="hidden h-4 w-4 p-1 tablet-sm:h-6 tablet-sm:w-6 tablet-sm:p-1.5 bg-primarySpaceGrey dark:bg-gainsboroGray rounded-full accordionExpandedIcon fill-white"
    />
    {children}
  </AccordionPrimitive.Trigger>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

/**
 * Applying transition on grid-template-row to retain expand/collapse animation
 * while keeping the AccordionContent in the DOM.
 * Reference:
 * - https://github.com/radix-ui/primitives/issues/2546
 * - https://github.com/radix-ui/primitives/issues/1155#issuecomment-2347981164
 */
const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      'overflow-hidden grid grid-rows-0fr data-[state=open]:grid-rows-1fr transition-grid-rows !duration-150',
    )}
    forceMount
    {...props}
  >
    <div className="min-h-0">
      <div className={className}>{children}</div>
    </div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
