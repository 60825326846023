import { ComponentProps } from 'react';
import { VideoProps } from '@/components/video/types';
import { ShareLinksProps } from '@/components/shareLinks/types';
import ImageAssetBlock from '@/components/ImageAssetBlock';
import { Props as RateLimitWidgetProps } from '@/components/rateLimitWidget';
import { Props as FormProps } from '../formWidget';

type AssetModalProps = ComponentProps<typeof ImageAssetBlock>;

export type TemplateProps = (
  | FormProps
  | VideoProps
  | ShareLinksProps
  | AssetModalProps
  | RateLimitWidgetProps
) & {
  contentType: string;
};

export enum ModalSize {
  FULL = 'FULL',
  COMPACT = 'COMPACT',
}

export type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  template: TemplateProps;
};
