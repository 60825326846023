import { cn } from '../../../../../../external/tailwind';
import Icon from '../../../../../icon';
import { Button } from '../../../../../button';

function BackNav({
  onClick,
  className,
}: {
  className?: string;
  onClick: () => void;
}) {
  return (
    <Button
      variant="bare"
      size="bare"
      className={cn(
        'flex justify-start items-center gap-2 tablet-sm:gap-4',
        'border-[none] border-b border-b-baseBlack/10',
        'py-4 tablet-sm:pb-6',
        className,
      )}
      onClick={onClick}
    >
      <Icon
        className="h-4 tablet-sm:h-[18px] rotate-90 rtl:-scale-y-100"
        icon="chevron"
      />
      <span className="py-0 uppercase text-mobile-sm/overline-2 tablet-sm:text-tablet-sm/overline-3">
        Back
      </span>
    </Button>
  );
}

export default BackNav;
