import dynamic, { LoaderComponent } from 'next/dynamic';
import { SVGProps as SVGElementProps } from 'react';

export type SVGProps = SVGElementProps<SVGSVGElement>;

const dynamicSVG = (importCallback: () => LoaderComponent<SVGProps>) =>
  dynamic<SVGProps>(importCallback);

const AddSlim = dynamicSVG(() => import('./assets/AddSlim'));
const AiStudio = dynamicSVG(() => import('./assets/AiStudio'));
const AmazonMusic = dynamicSVG(() => import('./assets/AmazonMusic'));
const Apple = dynamicSVG(() => import('./assets/Apple'));
const ArrowLeft = dynamicSVG(() => import('./assets/ArrowLeft'));
const ArrowRight = dynamicSVG(() => import('./assets/ArrowRight'));
const ArrowTriangle = dynamicSVG(() => import('./assets/ArrowTriangle'));
const Arrow = dynamicSVG(() => import('./assets/Arrow'));
const ArrowDown = dynamicSVG(() => import('./assets/ArrowDown'));
const AuthorPlaceholder = dynamicSVG(
  () => import('./assets/AuthorPlaceholder'),
);
const Back = dynamicSVG(() => import('./assets/Back'));
const Check = dynamicSVG(() => import('./assets/Check'));
const Chevron = dynamicSVG(() => import('./assets/Chevron'));
const Clock = dynamicSVG(() => import('./assets/Clock'));
const CloseCross = dynamicSVG(() => import('./assets/CloseCross'));
const Close = dynamicSVG(() => import('./assets/Close'));
const CompanySize = dynamicSVG(() => import('./assets/CompanySize'));
const CrossProduct = dynamicSVG(() => import('./assets/CrossProduct'));
const DocumentSpanner = dynamicSVG(() => import('./assets/DocumentSpanner'));
const Document = dynamicSVG(() => import('./assets/Document'));
const Dropdown = dynamicSVG(() => import('./assets/Dropdown'));
const Duration = dynamicSVG(() => import('./assets/Duration'));
const Facebook = dynamicSVG(() => import('./assets/Facebook'));
const Feature = dynamicSVG(() => import('./assets/Feature'));
const Funnel = dynamicSVG(() => import('./assets/Funnel'));
const G2 = dynamicSVG(() => import('./assets/G2'));
const GlassdoorSquareColored = dynamicSVG(
  () => import('./assets/GlassdoorSquareColored'),
);
const Globe = dynamicSVG(() => import('./assets/Globe'));
const GooglePodcast = dynamicSVG(() => import('./assets/GooglePodcast'));
const HamburgerMenu = dynamicSVG(() => import('./assets/HamburgerMenu'));
const Home = dynamicSVG(() => import('./assets/Home'));
const IdeaGearBulb = dynamicSVG(() => import('./assets/IdeaGearBulb'));
const Industry = dynamicSVG(() => import('./assets/Industry'));
const Insights = dynamicSVG(() => import('./assets/Insights'));
const Instagram = dynamicSVG(() => import('./assets/Instagram'));
const InstagramSquareColored = dynamicSVG(
  () => import('./assets/InstagramSquareColored'),
);
const Link = dynamicSVG(() => import('./assets/Link'));
const LinkedInSquareColored = dynamicSVG(
  () => import('./assets/LinkedInSquareColored'),
);
const Linkedin = dynamicSVG(() => import('./assets/Linkedin'));
const LinkedinV2 = dynamicSVG(() => import('./assets/LinkedinV2'));
const Location = dynamicSVG(() => import('./assets/Location'));
const Magic = dynamicSVG(() => import('./assets/Magic'));
const MailEnvelope = dynamicSVG(() => import('./assets/MailEnvelope'));
const Marketing = dynamicSVG(() => import('./assets/Marketing'));
const MinusSlim = dynamicSVG(() => import('./assets/MinusSlim'));
const Minus = dynamicSVG(() => import('./assets/Minus'));
const MonitorActive = dynamicSVG(() => import('./assets/MonitorActive'));
const Organization = dynamicSVG(() => import('./assets/Organization'));
const PlayCircle = dynamicSVG(() => import('./assets/PlayCircle'));
const Play = dynamicSVG(() => import('./assets/Play'));
const PlayRounded = dynamicSVG(() => import('./assets/PlayRounded'));
const PlayV2 = dynamicSVG(() => import('./assets/PlayV2'));
const Plus = dynamicSVG(() => import('./assets/Plus'));
const PlusRounded = dynamicSVG(() => import('./assets/PlusRounded'));
const Profile = dynamicSVG(() => import('./assets/Profile'));
const Quote = dynamicSVG(() => import('./assets/Quote'));
const Refresh = dynamicSVG(() => import('./assets/Refresh'));
const ResultsNotFound = dynamicSVG(() => import('./assets/ResultsNotFound'));
const ReviewQuote = dynamicSVG(() => import('./assets/ReviewQuote'));
const RoundedCheckbox = dynamicSVG(() => import('./assets/RoundedCheckbox'));
const Search = dynamicSVG(() => import('./assets/Search'));
const SearchV2 = dynamicSVG(() => import('./assets/SearchV2'));
const Service = dynamicSVG(() => import('./assets/Service'));
const Share = dynamicSVG(() => import('./assets/Share'));
const Social = dynamicSVG(() => import('./assets/Social'));
const Spotify = dynamicSVG(() => import('./assets/Spotify'));
const SprinklrBurstMonotone = dynamicSVG(
  () => import('./assets/SprinklrBurstMonotone'),
);
const SprinklrBurst = dynamicSVG(() => import('./assets/SprinklrBurst'));
const SprinklrFullLogoDark = dynamicSVG(
  () => import('./assets/SprinklrFullLogoDark'),
);
const SprinklrName = dynamicSVG(() => import('./assets/SprinklrName'));
const SprinklrFullLogo = dynamicSVG(() => import('./assets/SprinklrFullLogo'));
const Star = dynamicSVG(() => import('./assets/Star'));
const Stats = dynamicSVG(() => import('./assets/Stats'));
const TickCircle = dynamicSVG(() => import('./assets/TickCircle'));
const Twitter = dynamicSVG(() => import('./assets/Twitter'));
const VideoPlayer = dynamicSVG(() => import('./assets/VideoPlayer'));
const YoutubeMusic = dynamicSVG(() => import('./assets/YoutubeMusic'));
const Youtube = dynamicSVG(() => import('./assets/Youtube'));

const iconMap = {
  service: Service,
  social: Social,
  insights: Insights,
  marketing: Marketing,
  arrow: Arrow,
  chevron: Chevron,
  facebook: Facebook,
  twitter: Twitter,
  linkedin: Linkedin,
  linkedinV2: LinkedinV2,
  youtube: Youtube,
  instagram: Instagram,
  globe: Globe,
  sprinklrBurst: SprinklrBurst,
  hamburgerMenu: HamburgerMenu,
  play: Play,
  sprinklrFullLogoDark: SprinklrFullLogoDark,
  feature: Feature,
  quote: Quote,
  profile: Profile,
  reviewQuote: ReviewQuote,
  home: Home,
  arrowTriangle: ArrowTriangle,
  search: Search,
  searchV2: SearchV2,
  resultsNotFound: ResultsNotFound,
  plus: Plus,
  minus: Minus,
  industry: Industry,
  location: Location,
  companySize: CompanySize,
  arrowDown: ArrowDown,
  plusRounded: PlusRounded,
  close: Close,
  videoPlayer: VideoPlayer,
  check: Check,
  tickCircle: TickCircle,
  playRounded: PlayRounded,
  amazonMusic: AmazonMusic,
  googlePodcast: GooglePodcast,
  spotify: Spotify,
  apple: Apple,
  youtubeMusic: YoutubeMusic,
  clock: Clock,
  duration: Duration,
  playCircle: PlayCircle,
  share: Share,
  mailEnvelope: MailEnvelope,
  link: Link,
  playV2: PlayV2,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  closeCross: CloseCross,
  crossProduct: CrossProduct,
  linkedInSquareColored: LinkedInSquareColored,
  instagramSquareColored: InstagramSquareColored,
  glassdoorSquareColored: GlassdoorSquareColored,
  document: Document,
  sprinklrBurstMonotone: SprinklrBurstMonotone,
  organization: Organization,
  refresh: Refresh,
  magic: Magic,
  stats: Stats,
  authorPlaceholder: AuthorPlaceholder,
  roundedCheckbox: RoundedCheckbox,
  back: Back,
  dropDown: Dropdown,
  addSlim: AddSlim,
  minusSlim: MinusSlim,
  ideaGearBulb: IdeaGearBulb,
  documentSpanner: DocumentSpanner,
  star: Star,
  funnel: Funnel,
  g2: G2,
  aiStudio: AiStudio,
  monitorActive: MonitorActive,
  sprinklrName: SprinklrName,
  sprinklrFullLogo: SprinklrFullLogo,
};

export type IconType = keyof typeof iconMap;

type Props = SVGProps & {
  icon: IconType;
  alt?: string;
  className?: string;
};

const Icon = ({ icon, className, ...props }: Props) => {
  const Component = iconMap[icon];
  return Component ? <Component {...props} className={className} /> : null;
};

export default Icon;
