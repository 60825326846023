import React from 'react';
import Icon from '../../../../../icon';
import { cn } from '../../../../../tailwind';

type Props = {
  label: string;
  isArrowEnabled?: boolean;
  isArrowDown?: boolean;
  className?: string;
};

const MenuLabel = ({ label, className, isArrowEnabled = false }: Props) => {
  return (
    <div
      className={cn(
        'flex justify-between items-center w-full text-black',
        'py-4 tablet-sm:pt-8 tablet-sm:pb-6',
        className,
      )}
    >
      <span
        className={cn(
          'text-mobile-sm/overline-2 tablet-sm:text-tablet-sm/overline-1 uppercase py-0',
        )}
      >
        {label}
      </span>
      {isArrowEnabled ? (
        <Icon
          className={cn(
            'transition-transform duration-200 ease-linear fill-baseBlack -rotate-90',
            'h-4 tablet-sm:h-[18px] rtl:-scale-y-100',
          )}
          icon="chevron"
        />
      ) : null}
    </div>
  );
};

export default MenuLabel;
