import { IconType } from '@/external/icon';

type ShareLinkConfig = {
  icon: IconType;
  shareLinkGenerator: (params: { linkTitle: string; link: string }) => string;
  isOpenInNewWindow?: boolean;
};
export const SHARE_CHANNELS = [
  'FACEBOOK',
  'TWITTER',
  'LINKEDIN',
  'EMAIL',
] as const;
export type ShareChannel = (typeof SHARE_CHANNELS)[number];
export const SHARE_LINK_CONFIG_PER_CHANNEL: Record<
  ShareChannel,
  ShareLinkConfig
> = {
  FACEBOOK: {
    icon: 'facebook',
    shareLinkGenerator({ link }) {
      const shareLink = new URL('https://www.facebook.com/sharer.php');
      shareLink.searchParams.append('u', link);
      return shareLink.toString();
    },
    isOpenInNewWindow: true,
  },
  TWITTER: {
    icon: 'twitter',
    shareLinkGenerator({ link, linkTitle }) {
      const shareLink = new URL('https://twitter.com/intent/tweet');
      shareLink.searchParams.append('text', linkTitle);
      shareLink.searchParams.append('url', link);
      return shareLink.toString();
    },
    isOpenInNewWindow: true,
  },
  LINKEDIN: {
    icon: 'linkedin',
    shareLinkGenerator({ link, linkTitle }) {
      const shareLink = new URL('https://www.linkedin.com/shareArticle');
      shareLink.searchParams.append('title', linkTitle);
      shareLink.searchParams.append('url', link);
      return shareLink.toString();
    },
    isOpenInNewWindow: true,
  },
  EMAIL: {
    icon: 'mailEnvelope',
    shareLinkGenerator({ link, linkTitle }) {
      return `mailto:?subject=Sprinklr.com: ${linkTitle}&body=${link}`;
    },
  },
};

export const BLOG_SUBNAV_CONTENT_TYPE = 'blogSubNav';
export const BLOG_POST_CONTENT_TYPE = 'blogPost';
export const HOME_LINK_LABEL = 'Blog Home';
export const CATEGORIES_MENU_LABEL = 'Blog Categories';
export const PLACEHOLDER_ITEM_LABEL = 'COMING SOON';
export const MOBILE_ACCORDION_MENU_LABEL = 'MORE';
export const DEFAULT_IMAGE_TITLE = 'RichTextEmbeddedImage';
